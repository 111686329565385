import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonCard
  ]
})
export class DocumentViewerComponent implements OnInit {
  @Input() documentTitle: string;
  @Input() documentPath: string;
  documentContent: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    // Set documentPath from route data instead of component input if specified
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        if (data['documentPath']) {
          this.documentPath = data['documentPath'];
        }
        if (data['documentTitle']) {
          this.documentTitle = data['documentTitle'];
        }
      }

      this.httpClient
        .get(this.documentPath, { responseType: 'text' })
        .subscribe((content) => {
          this.documentContent = content;
        });
    });
  }
}
