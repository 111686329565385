<ion-content
  fullscreen
  class="ion-padding ion-justify-content-center ion-text-center loading-cont"
  scroll-y="false"
>
  <div class="main">
    <img id="logo" src="assets/icon/favicon.png" alt="Buildshare icon" />
    <p>Click close if the window doesn't close automatically.</p>
  </div>
</ion-content>
