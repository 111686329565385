import { Component, NgZone, OnInit } from '@angular/core';
import { Performance, trace } from '@angular/fire/performance';
import {
  GuardsCheckEnd,
  NavigationCancel,
  NavigationEnd,
  ResolveEnd,
  Router
} from '@angular/router';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  IonApp,
  IonModal,
  IonRouterOutlet,
  ModalController,
  ToastController
} from '@ionic/angular/standalone';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { compareVersions } from 'compare-versions';
import { filter, first } from 'rxjs';
import { environment } from '../environments/environment';
import { version } from './../version';
import { ErrorComponent } from './core/components/error/error.component';
import { LoadingComponent } from './core/components/loading/loading.component';
import { UpdateAppModal } from './core/modals/update-app.modal';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { BiometricsService } from './core/services/biometrics/biometrics.service';
import { ConfigService } from './core/services/config/config.service';
import { NotificationService } from './core/services/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonModal, LoadingComponent, IonApp, IonRouterOutlet, ErrorComponent]
})
export class AppComponent implements OnInit {
  loading = true;
  biometricsFailed = false;
  updateModal: HTMLIonModalElement;

  constructor(
    private router: Router,
    private zone: NgZone,
    private analytics: AnalyticsService,
    private performance: Performance,
    private notifications: NotificationService,
    private toasts: ToastController,
    private modals: ModalController,
    private config: ConfigService,
    private biometrics: BiometricsService
  ) {}

  async ngOnInit() {
    const startupTrace = trace(this.performance, 'angularStartup');
    startupTrace.start();
    await SplashScreen.hide();

    // Initiate page tracking ==========================================
    this.analytics.trackPageViews();
    // Listen for notifications ========================================
    this.notifications.addPushListeners();
    // Initialise Facebook SDK ==========================================
    this.initialiseFacebook();

    // Add listener for handling deep links ============================
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Close internal browser if stripe redirect custom scheme
      if (event.url === environment.stripe.redirectScheme) {
        Browser.close();
      } else {
        // Handle universal/deep links
        this.zone.run(() => {
          const slug = event.url.replace(environment.universalLinkUrl, '');
          if (slug) {
            this.router.navigateByUrl(slug);
          }
        });
      }
    });

    // Hide splash screen when route is resolved =======================
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof GuardsCheckEnd ||
            event instanceof NavigationCancel ||
            event instanceof ResolveEnd
        ),
        first()
      )
      .subscribe(() => {
        this.loading = false;
        startupTrace.stop();
      });

    if (Capacitor.isNativePlatform()) {
      // Monitor app version and show update modal if necessary
      await this.monitorMinAppVersion();
      // Check biometrics lock
      await this.biometricsCheck();
    } else {
      this.checkCookieConsent();
    }
  }

  checkCookieConsent() {
    let consent;
    try {
      consent = localStorage.getItem('cookieConsent');
    } catch (error) {
      consent = false;
    }
    if (!consent) {
      this.toasts
        .create({
          message: 'By using this site, you consent to our cookie policy.',
          translucent: true,
          buttons: [
            {
              text: 'View',
              role: 'info',
              handler: () => this.router.navigate(['/cookie-policy'])
            },
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
                try {
                  localStorage.setItem('cookieConsent', 'true');
                } catch (err) {
                  console.error(err);
                }
              }
            }
          ]
        })
        .then((toast) => toast.present());
    }
  }

  async monitorMinAppVersion() {
    // Check if the current version is less than the minimum required
    this.config
      .getValueSubject<string>('minVersion', 'string')
      .pipe(filter((min) => !!min))
      .subscribe(async (min) => {
        console.log('Checking version', version.name, min);
        if (compareVersions(version.name, min) < 0) {
          if (!this.updateModal) {
            this.updateModal = await this.modals.create({
              component: UpdateAppModal
            });
            await this.updateModal.present();
          }
        } else {
          if (this.updateModal) {
            await this.updateModal.dismiss();
            this.updateModal = undefined;
          }
        }
      });
  }

  async initialiseFacebook(): Promise<void> {
    await FacebookLogin.initialize({ appId: environment.facebook.appId });
    if (Capacitor.getPlatform() === 'ios') {
      let trackingPermission = await AppTrackingTransparency.getStatus();
      if (trackingPermission.status == 'notDetermined') {
        trackingPermission = await AppTrackingTransparency.requestPermission();
      }
      await FacebookLogin.setAdvertiserTrackingEnabled({
        enabled: trackingPermission.status == 'authorized'
      });
    }
  }

  async biometricsCheck() {
    const biometricsLockEnabled =
      await this.biometrics.isBiometricsLockEnabled();
    const biometricsAvailable = await this.biometrics.isAvailable();
    if (biometricsLockEnabled && biometricsAvailable) {
      try {
        await this.biometrics.authenticate();
        this.biometricsFailed = false;
      } catch (error) {
        this.biometricsFailed = true;
      }
    }
  }
}
