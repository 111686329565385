import { Platform } from '@angular/cdk/platform';
import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { canActivate } from '@angular/fire/auth-guard';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { appStoreUrl, playStoreUrl } from 'src/environments/shared';
import { DocumentViewerComponent } from './core/components/document-viewer/document-viewer.component';
import { isMobileGuard } from './core/guards/is-mobile.guard';
import { redirectUnauthorizedToLanding } from './core/services/account/auth.guards';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { HowItWorksComponent } from './project/invest/how-it-works/how-it-works.component';
import { ReferComponent } from './refer/refer.component';
import { StripeRedirectComponent } from './stripe-redirect/stripe-redirect.component';

export const APP_ROUTES: Routes = [
  {
    path: 'landing',
    title: 'Invest in UK property development',
    loadComponent: () =>
      import('./landing/landing.page').then((p) => p.LandingPage)
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.routes').then((r) => r.ACCOUNT_ROUTES)
  },
  {
    path: 'project',
    loadChildren: () =>
      import('./project/project.routes').then((r) => r.PROJECT_ROUTES),
    canActivate: [isMobileGuard]
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.routes').then((r) => r.PAYMENT_ROUTES),
    canActivate: [isMobileGuard]
  },
  {
    path: 'refer',
    component: ReferComponent,
    title: 'Refer a Friend',
    loadChildren: () =>
      import('./refer/refer.routes').then((r) => r.REFER_ROUTES),
    canActivate: [isMobileGuard]
  },
  {
    path: 'terms-and-conditions',
    component: DocumentViewerComponent,
    data: {
      documentTitle: 'Terms and Conditions',
      documentPath: 'assets/legal/terms-and-conditions.html'
    },
    title: 'Terms & Conditions'
  },
  {
    path: 'privacy-policy',
    component: DocumentViewerComponent,
    data: {
      documentTitle: 'Privacy Policy',
      documentPath: 'assets/legal/privacy-policy.html'
    },
    title: 'Privacy Policy'
  },
  {
    path: 'cookie-policy',
    component: DocumentViewerComponent,
    data: {
      documentTitle: 'Cookie Policy',
      documentPath: 'assets/legal/cookie-policy.html'
    },
    title: 'Cookie Policy'
  },
  {
    path: 'risk',
    component: DocumentViewerComponent,
    data: {
      documentTitle: 'Risk Statement',
      documentPath: 'assets/legal/risk-statement.html'
    },
    title: 'Risk Statement'
  },
  {
    path: 'referral-terms',
    component: DocumentViewerComponent,
    data: {
      documentTitle: 'Referral Terms',
      documentPath: 'assets/legal/referral-terms.html'
    },
    title: 'Referral Terms'
  },
  {
    path: 'faqs',
    title: 'FAQs',
    loadComponent: () => import('./faqs/faqs.page').then((m) => m.FaqsPage)
  },
  {
    path: 'investment-guide',
    component: HowItWorksComponent,
    title: 'Investment guide'
  },
  {
    path: 'stripe-redirect',
    component: StripeRedirectComponent
  },
  {
    path: 'download',
    redirectTo: ({ queryParams }) => {
      const analytics = inject(AnalyticsService);
      analytics.pageView('/download');
      if (Capacitor.isNativePlatform()) {
        return '/';
      }
      const platform = inject(Platform);
      let queryParamsString = new HttpParams({
        fromObject: queryParams
      }).toString();
      if (platform.IOS) {
        if (queryParamsString) {
          queryParamsString = '?' + queryParamsString;
        }
        window.location.href = appStoreUrl + queryParamsString;
      } else if (platform.ANDROID) {
        if (queryParamsString) {
          queryParamsString = '&' + queryParamsString;
        }
        window.location.href = playStoreUrl + queryParamsString;
      }
      return '/';
    }
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then((r) => r.TAB_ROUTES),
    ...canActivate(redirectUnauthorizedToLanding)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@Injectable({ providedIn: 'root' })
export class BuildshareTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`Buildshare | ${title}`);
    }
  }
}
