import { Platform } from '@angular/cdk/platform';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

export const isMobileGuard: CanActivateFn = () => {
  if (Capacitor.isNativePlatform()) {
    return true;
  }
  const platform = inject(Platform);
  if (platform.IOS || platform.ANDROID) {
    return true;
  } else {
    const router = inject(Router);
    return router.createUrlTree(['/']);
  }
};
