@if (biometricsFailed) {
  <app-error
    message="Biometric authentication failed. Please try again."
    [customButton]="true"
    customButtonText="Try again"
    (customButtonEvent)="biometricsCheck()"
  ></app-error>
} @else if (loading) {
  <app-loading></app-loading>
} @else {
  <ion-app>
    <ion-router-outlet></ion-router-outlet>
  </ion-app>
}
8
