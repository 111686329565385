import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-stripe-redirect',
  templateUrl: './stripe-redirect.component.html',
  styleUrls: ['./stripe-redirect.component.scss'],
  standalone: true,
  imports: [IonContent]
})
export class StripeRedirectComponent implements OnInit {
  ngOnInit() {
    window.location.href = environment.stripe.redirectScheme;
  }
}
