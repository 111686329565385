import { Platform } from '@angular/cdk/platform';
import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonTitle,
  IonToolbar
} from '@ionic/angular/standalone';
import { appStoreUrl, playStoreUrl } from 'src/environments/shared';

@Component({
  selector: 'app-update-modal',
  standalone: true,
  imports: [
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonFooter,
    NgOptimizedImage
  ],
  template: `
    <ion-header translucent>
      <ion-toolbar>
        <ion-title>Update required</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding ion-text-center">
      <div id="graphic" class="ion-padding-top">
        <img
          ngSrc="assets/core/graphics/construction.svg"
          alt="Referrals graphic"
          width="150"
          height="123"
        />
      </div>
      <h2 class="ion-padding-top">Please update your app</h2>
      <p class="ion-padding">
        We've made some changes to the foundations that require an app update.
      </p>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <small class="ion-padding"
          >Please update your app to the latest version to continue.</small
        >
        <ion-button
          class="ion-margin"
          expand="block"
          size="large"
          (click)="update()"
          >Update</ion-button
        >
      </ion-toolbar>
    </ion-footer>
  `,
  styles: [
    `
      #graphic {
        position: relative;
        width: 150px;
        margin: auto;

        img {
          width: auto;
          height: auto;
        }
      }
    `
  ]
})
export class UpdateAppModal {
  constructor(private platform: Platform) {}

  update() {
    if (this.platform.IOS) {
      window.location.href = appStoreUrl;
    } else if (this.platform.ANDROID) {
      window.location.href = playStoreUrl;
    } else {
      throw new Error(
        'Error trying to direct to app store. Unsupported platform.'
      );
    }
  }
}
