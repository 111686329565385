<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        class="icon-back-button"
        text=""
        defaultHref="/"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{ documentTitle }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div id="content">
    <ion-header collapse="condense">
      <ion-toolbar color="none">
        <ion-title size="large">{{ documentTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-card class="translucent ion-padding">
      <div id="document-content" [innerHtml]="documentContent"></div>
    </ion-card>
  </div>
</ion-content>
